 .navbar{
    height: 70px;
    width: 100%;
    position: fixed;
    overflow-x: hidden;
    top: 0; 
    z-index: 5;  
    transition: 300ms;
    display:flex;
    align-items: center;  
 }
 .navbar.navColor{
    background:white;
 }
 .navLogo{
    height: 40px;
    transition: 0.5s;
 }
 .language{
    border: 1px solid#EA5136;
    border-radius: 10px; 
    padding: 0;
    margin: 0;
    width: 70px;
    cursor: pointer;
 }
 .english{ 
    border-radius: 10px 0 0 10px;
 }
 .gujrati{ 
    border-radius: 0 10px 10px 0;
 }
 
 .menus{
    height: 100vh;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#16175f;
    clip-path: circle(0% at 100% 0); 
    transition: 0.5s;  
 }
 .menus.active{
    clip-path: circle(150% at 100% 0); 
    transition: 1s;    
 }
.hamburger .line{
    width: 30px;
    height: 3px;
    background-color:#EA5136;
    display: block;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
    transition: 1s;
    
  }
  
  .hamburger:hover{
    cursor: pointer;
  }
  
  #hamburger{
    position: relative;
    right:20px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  #hamburger.is-active{
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #hamburger:before{
    content: "";
    position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border: 3px solid transparent;
    top: calc(85% - 30px);
    left: calc(85% - 30px);
    border-radius: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  #hamburger.is-active:before{
    border: 3px solid#EA5136; 
  
  }
  
  #hamburger.is-active .line{
    width: 25px;
    background-color:#EA5136; 
  }
  
  #hamburger.is-active .line:nth-child(2){
    opacity: 0;
    background-color:#EA5136;
  
  }
  
  #hamburger.is-active .line:nth-child(1){
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    -o-transform: translateY(6px);
    transform: translateY(6px);
    background-color:#EA5136;
  
  }
  
  #hamburger.is-active .line:nth-child(3){
    -webkit-transform: translateY(-11px) rotate(90deg);
    -ms-transform: translateY(-11px) rotate(90deg);
    -o-transform: translateY(-11px) rotate(90deg);
    transform: translateY(-11px) rotate(90deg);
    background-color:#EA5136; 
  }

  @media (max-width: 768px) {
    .navLogo{
        height: 30px;
        transition: 0.5s;
     }
  }