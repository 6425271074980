.achivPc{
    display: block;
}
.achivMb{
    display: none;
}
@media (max-width:768px){
    .achivPc{
        display: none;
    }
    .achivMb{
        display: block;
    }
}