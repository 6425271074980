:root {
    --ease-relaxed: cubic-bezier(0.28, 0.11, 0.02, 1);
  } 
  
 .anime{
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
 }
 
 
 
  .anime.is-hidden {
    opacity: 0;
    filter: none;
    transform: translateY(0);
    transition: opacity 300ms ease;
  }
  .anime.is-hidden span {
    opacity: 1;
    -webkit-mask-image: none;
    mask-image: none;
    transform: none;
  }
  
 
  /* anime-fade-split-y */
  .anime-fade-split-y {
    display: flex;
    white-space: break-spaces;
  }
  .anime-fade-split-y span {
    opacity: 0;
    transform: translateY(0.6em);
  }
  .anime-fade-split-y.is-active span {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 800ms  ease, transform 800ms var(--ease-relaxed);
    transition-delay: calc(var(--index, 0s) * 20ms);
  }
  
  