.initiativesCartDiv{
    position: relative;
    height: 280vh;
}

.initiativesCart{
    position: sticky;
    height: 50vh;
    left: 0;
}

@media(max-width:768px){
    .initiativesCartDiv{
        position: relative;
        height: 300vh;
    }
    .initiativesCart{
        position: sticky;
        height: 70vh;
        left: 0;
    }
}