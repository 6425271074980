.timeline {
    height: 200vh;
    width: 100%;
    position: relative;  
  }  
  .tline{
    position: absolute;
    top: 50px;
    height: 190vh;
    width: 100%; 
  
  }  
  .timeLineNews{
    width: 100%;
    height: 100%; 
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 3;
  }

  .tPoint{
    width: 100%;  
    height: 50vh; 
  }
  .showLine{
    height: 100vh;
    width: 100%;
    position: sticky !important; 
    top: 0;
    left: 0; 
    z-index:1;
 
   
  }

  .tlImg > img{
    filter: grayscale(100%);  
    transition: 1s;
  }
  .tlImg:hover > img { 
    filter: grayscale(0%); 
    transition: 1s; 
  }
  @media (max-width: 1110px) {
    .tline{
      position: absolute;
      top: 0;
      height: 200vh;
      width: 100%; 
    }
  }
  
  @media (max-width:768px){
    .timeLineNews{
      width: 100%;
      height: 100%; 
      position: relative;
    
      z-index: 3;
    }
    .showLine{
      display: none !important;
    }
    .timeline {
        height: auto;
        width: 100%;
        position: relative;
        /* background-color: red; */
      }  
    .tPoint{
      height: auto;
        margin-top: 50px;
        position: relative;
    }
    .tline{
      display: none;
    }
  }