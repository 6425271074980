.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
overflow-y: auto;
overflow-x: hidden;
  z-index: 1000;
} 
.full-img {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 8px;
  margin: auto;
}

.aboutTop {
  background-image: url("../images/about1.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  height: 100vh;
}
.projectDetailTopSouraurja {
  background-image: url("../images/solar.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
}
.projectDetailTopKdcc {
  background-image: url("../images/kdcc.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
}
.aboutMission {
  width: 450px;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #ea5136;
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 18px;
  background-position: center;

}
.experienceText {
  font-size: 150px;
}
.orangeBg {
  background-image: radial-gradient(circle 80vh at 40% 0%, #ec300f 0%,#e74e33 25%,#ec593f 50%, #f16a52 75%, #F5F2F2 120%); 
  background-size: 120% 100%;
  background-repeat: no-repeat;
  /* background-image: url("../images/bgOrangepc.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow-x: hidden;
}
.blueBg {
  background-image: radial-gradient(circle 80vh at 40% 0%, #293fb6 0%,#384cbb 25%,#6977c5 50%, #8a96ce 75%, #F5F2F2 120%); 
  background-size: 120% 100%;
  background-repeat: no-repeat;
  /* background-image: url("../images/bgBluepc.png"); */
  /* background-repeat: no-repeat;
  background-size: 100% 100%; */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow-x: hidden;
}
.achivmentspc {
  position: relative;
  height: 60vh;
}
.achivpc1,
.achivpc2,
.achivpc3,
.achivpc4 {
  width: 20%;
  height: 70%;
  position: absolute;
  border-radius: 15px;
  transition: 2s;
}
.achivmentsmb {
  display: none;
}
.benifitsUp {
  position: relative;
  height: 80vh;
  font-size: 12px;
  text-align: center;
  transition: 1s;
  font-weight: bold;
}

.benifitsUpPoint1 {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d2344;
  color: white;
  position: absolute;
  top: 10%;
  left: 5%;
  transition: 1s;
}

.benifitsUpPoint2 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #727cb6;
  color: white;
  position: absolute;
  top: 60%;
  left: 25%;
  transition: 1s;
}

.benifitsUpPoint3 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #727cb6;
  color: white;
  position: absolute;
  top: 8%;
  left: 35%;
  transition: 1s;
}

.benifitsUpPoint4 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ea5136;
  color: white;
  position: absolute;
  top: 55%;
  left: 45%;
  transition: 1s;
}

.benifitsUpPoint5 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d2344;
  color: white;
  position: absolute;
  top: 15%;
  left: 60%;
  transition: 1s;
}

.benifitsUpPoint6 {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #727cb6;
  color: white;
  position: absolute;
  top: 30%;
  left: 70%;
  transition: 1s;
}
.newsTextBox{
  height: 300px;
  overflow: hidden;
  position: relative;
  transition: 3s;
}
.newsTextBox.active{
  height: auto;
  overflow: unset;
  transition: 3s;
}
.newsTextButton{
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(rgba(0,0,0,0.1) 25%,rgba(0,0,0,0.5) );
  border-radius: 0 0 5px 5px;
  color: #EA5136;
}
.galleryPopUp{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5 ;
}

.sideBar{
  position: fixed;
  background-color: #EA5136;
  top: 20%;
  right: -120px;
  width: 120px;
  transition: 1s;
  z-index: 5;
}
.sideBar:hover{
  right: 0px;
  transition: 1s; 
  z-index: 5;

}
.sideBar>div{
  position: relative; 
}
.slideButton{
  height: 100px;
  position: absolute;
  left: -20px;
  top: 30%;
  background-color: #EA5136;
  display: flex;
  align-items: center;
  border-radius: 20px 0 0 20px;
}
.instagram-media{
  margin: auto !important;
}
.text-black{
  color: #232A50 !important;
}
 

@media (max-width: 990px) {
  .achivmentspc {
    display: none;
  }
  .achivmentsmb {
    position: relative;
    margin-top: -100px;
    height: 180vh;
    display: block;
    /* background-color: #EA5136; */
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .achivmb1,
  .achivmb2,
  .achivmb3,
  .achivmb4 {
    width: 250px;
    height: 250px;
    border-radius: 15px;
    transition: 2s;
    clip-path: inset(50% 50% 50% 50%);
    transform: rotate(360deg);
  }
  .achivmb1.active,
  .achivmb2.active,
  .achivmb3.active,
  .achivmb4.active {
    width: 250px;
    height: 250px;
    border-radius: 15px;
    transition: 2s;
    clip-path: inset(0 0 0 0);
  }

  .benifitsUpPoint1 {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d2344;
    color: white;
    position: absolute;
    top: 5%;
    left: 5%;
    transition: 1s;
  }

  .benifitsUpPoint2 {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #727cb6;
    color: white;
    position: absolute;
    top: 50%;
    left: 25%;
    transition: 1s;
  }

  .benifitsUpPoint3 {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #727cb6;
    color: white;
    position: absolute;
    top: 8%;
    left: 40%;
    transition: 1s;
  }

  .benifitsUpPoint4 {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ea5136;
    color: white;
    position: absolute;
    top: 50%;
    left: 45%;
    transition: 1s;
  }

  .benifitsUpPoint5 {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d2344;
    color: white;
    position: absolute;
    top: 20%;
    left: 70%;
    transition: 1s;
  }

  .benifitsUpPoint6 {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #727cb6;
    color: white;
    position: absolute;
    top: 50%;
    left: 70%;
    transition: 1s;
  }
}

@media (max-width: 768px) {
  .aboutTop {
    background-image: url("../images/about1_1.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    height: 100vh;
  }
  .projectDetailTop {
    background-image: url("../images/project1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
  }
  .experienceText {
    font-size: 100px;
  }
  /* .orangeBg {
    background-image: url("../images/bgOrangemb.png");
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    overflow-x: hidden;
  } */
  /* .blueBg {
    background-image: url("../images/bgBluemb.png");
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    overflow-x: hidden;
  } */

  .benifitsUpPoint1 {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d2344;
    color: white;
    position: absolute;
    top: 5%;
    left: 3%;
    transition: 1s;
  }

  .benifitsUpPoint2 {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #727cb6;
    color: white;
    position: absolute;
    top: 60%;
    left: 5%;
    transition: 1s;
  }

  .benifitsUpPoint3 {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #727cb6;
    color: white;
    position: absolute;
    top: 8%;
    left: 40%;
    transition: 1s;
  }

  .benifitsUpPoint4 {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ea5136;
    color: white;
    position: absolute;
    top: 55%;
    left: 30%;
    transition: 1s;
  }

  .benifitsUpPoint5 {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d2344;
    color: white;
    position: absolute;
    top: 30%;
    left: 75%;
    transition: 1s;
  }

  .benifitsUpPoint6 {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #727cb6;
    color: white;
    position: absolute;
    top: 50%;
    left: 60%;
    transition: 1s;
  }
}

@media (max-width: 550px) {
  .aboutTop {
    background-image: url("../images/about1_1.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    height: 100vh;
  }
  .aboutMission {
    width: 310px;
    height: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #ea5136;
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 14px;
  }
  .projectDetailTop {
    background-image: url("../images/project1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
  }
  .experienceText {
    font-size: 55px;
  }
  .benifitsUp {
    position: relative;
    height: 100vh;
    font-size: 12px;
    text-align: center;
    transition: 1s;
    font-weight: bold;
  }

  .benifitsUpPoint1 {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d2344;
    color: white;
    position: absolute;
    top: 5%;
    left: 3%;
    transition: 1s;
  }

  .benifitsUpPoint2 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #727cb6;
    color: white;
    position: absolute;
    top: 10%;
    left: 65%;
    transition: 1s;
  }

  .benifitsUpPoint3 {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #727cb6;
    color: white;
    position: absolute;
    top: 30%;
    left: 45%;
    transition: 1s;
  }

  .benifitsUpPoint4 {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ea5136;
    color: white;
    position: absolute;
    top: 50%;
    left: 5%;
    transition: 1s;
  }

  .benifitsUpPoint5 {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d2344;
    color: white;
    position: absolute;
    top: 58%;
    left: 60%;
    transition: 1s;
  }

  .benifitsUpPoint6 {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #727cb6;
    color: white;
    position: absolute;
    top: 75%;
    left: 35%;
    transition: 1s;
  }
}
