.App {
  text-align: center; 
}
.textUnderLine{
  position: relative;
  width: fit-content;
 
}
.textUnderLine p{
  font-weight: 600;
}
.tJustify{
  text-align: justify;
}
.underLine{
  background-image: url('./images/line.png');
  background-repeat: no-repeat;
  background-position: center bottom;
}
.textLineDrow1{
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600; 
  content: "";
  display: block;
  height: 100px;
  background-image: url('./images/line.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  clip-path: inset(0 100% 0 0); 
  transition: 0.5s;
}

.textLineDrow1.active{
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600; 
  content: "";
  display: block;
  height: 100px;
  background-image: url('./images/line.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: 0.5s;
  clip-path: inset(0 0 0 0); 
}
 

.textLineDrow2{
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600; 
  content: "";
  display: block;
  height: 100px;
  background-image: url('./images/line.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  clip-path: inset(0 100% 0 0); 
  transition: 0.5s;
}

.textLineDrow2.active{
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600; 
  content: "";
  display: block;
  height: 100px;
  background-image: url('./images/line.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: 0.5s;
  clip-path: inset(0 0 0 0); 
}

.textLineDrow3{
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600; 
  content: "";
  display: block;
  height: 100px;
  background-image: url('./images/line.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  clip-path: inset(0 100% 0 0); 
  transition: 0.5s;
}

.textLineDrow3.active{
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600; 
  content: "";
  display: block;
  height: 100px;
  background-image: url('./images/line.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: 0.5s;
  clip-path: inset(0 0 0 0); 
}
 
 @media (max-width: 768px) {
  .textLineDrow1{ 
    height: 70px; 
  }
  
  .textLineDrow1.active{ 
    height: 70px; 
  }
  .textLineDrow2{ 
    height: 70px; 
  }
  
  .textLineDrow2.active{ 
    height: 70px; 
  }
  .textLineDrow3{ 
    height: 70px; 
  }
  
  .textLineDrow3.active{ 
    height: 70px; 
  }
 }