 
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 
@import url('https://fonts.googleapis.com/css2?family=Anek+Gujarati:wdth,wght@75..125,100..800&display=swap');
 
body {
  margin: 0; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #F5F2F2;
  color:#1d2b77 !important
}
.text-black{
  color: #1d2b77 !important;
}
p  {
  margin: 0;
  padding: 0; 
  
}

.poppins{
  font-family: "Poppins", sans-serif;  
}
.anekGujarati{
  font-family: "Anek Gujarati", sans-serif; 
}
.justifyT{
  text-align: justify;
}

 div {
  margin: 0;
  padding: 0;
}

button{
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-btn {
  width: auto;
  height: auto;
  color: #fff;
  border-radius: 0px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1); */
  outline: none;
}


.btnBlue {
  background: #232A50;  
  line-height: 42px;
  padding: 0;
  border: none;
  
}
.btnBlue span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding:4px 20px;

}
.btnBlue:before,
.btnBlue:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
   background: #232A50;
  transition: all 0.3s ease;
}
.btnBlue:before {
  height: 0%;
  width: 2px;
}
.btnBlue:after {
  width: 0%;
  height: 2px;
}
.btnBlue:hover{
   background: transparent;
  box-shadow: none;
}
.btnBlue:hover:before {
  height: 100%;
}
.btnBlue:hover:after {
  width: 100%;
}
.btnBlue span:hover{
   color: #232A50;
}
.btnBlue span:before,
.btnBlue span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
   background: #232A50;
  transition: all 0.3s ease;
}
.btnBlue span:before {
  width: 2px;
  height: 0%;
}
.btnBlue span:after {
  width: 0%;
  height: 2px;
}
.btnBlue span:hover:before {
  height: 100%;
}
.btnBlue span:hover:after {
  width: 100%;
}



.btnOrange {
  background: #EA5136;  
  line-height: 42px;
  padding: 0;
  border: none;
  
}
.btnOrange span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding:4px 20px;
}
.btnOrange:before,
.btnOrange:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
   background: #EA5136;
  transition: all 0.3s ease;
}
.btnOrange:before {
  height: 0%;
  width: 2px;
}
.btnOrange:after {
  width: 0%;
  height: 2px;
}
.btnOrange:hover{
   background: transparent;
  box-shadow: none;
}
.btnOrange:hover:before {
  height: 100%;
}
.btnOrange:hover:after {
  width: 100%;
}
.btnOrange span:hover{
   color: #EA5136;
}
.btnOrange span:before,
.btnOrange span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
   background: #EA5136;
  transition: all 0.3s ease;
}
.btnOrange span:before {
  width: 2px;
  height: 0%;
}
.btnOrange span:after {
  width: 0%;
  height: 2px;
}
.btnOrange span:hover:before {
  height: 100%;
}
.btnOrange span:hover:after {
  width: 100%;
}

 

strong:after {
  font-weight: 600; 
  content: "";
  display: block;
  height: 20px;
  background-image: url('./images/line.png');
  background-repeat: no-repeat;
  background-position: center bottom;
}
.react-multiple-carousel__arrow--left {
  z-index: 0 !important;
}
.react-multiple-carousel__arrow--right {
  z-index: 0 !important;
}
 
.bgd{
  background-size: 100% 100%;
  background-repeat: no-repeat; 
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #EA5136; 
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c21d00; 
}



