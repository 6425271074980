.uperFoterPosition {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    padding: 1rem 0;
    color: #fff;
}

.uperFoterPosition:hover .uperFoterPosition__item {
    animation-play-state: paused;
}

.uperFoterPosition__seperator {
    margin: 0 3rem;
}

.uperFoterPosition__item {
    display: inline-block;
    will-change: transform;
    animation: uperFoterPosition 25s linear infinite;
}
.uperFooterImg {
  mix-blend-mode: color-burn !important;
  aspect-ratio: 3/2 !important;
  object-fit: cover;
  width: 150px;
  /* aspect-ratio: 3/2; */
}

@keyframes uperFoterPosition {
    0% {
        transform: translateX(50%);
    }

    100% {
        transform: translateX(-50%);
    }
}

.footerIcon a{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
    background-color:#1d2344 ;
    color: white !important;
    transition: 0.5s;
    cursor: pointer;
}
.footerIcon a:hover{
    background-color: #727CB6;
    transition: 0.5s;
}

