.homeTop {
  position: relative;
}
.homeTopA {
  height: 100vh;
  background-image: url("../../images/homeTopBg.png");
  object-fit: cover;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  overflow-y: hidden;
}
.homeTopTejasImg {
  position: absolute;
  bottom: 0;
  left: 5%;
}
.homeTopTejasImg > img {
  height: 110vh;
}

.frem {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerFrem {
  width: 25px;
  height: 25px;
}
.outerFrem {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
}
.homeCart:hover {
  background-color: #ea5136;
}
.homeCart:hover .outerFrem {
  animation: spin infinite 5s linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.homeProject {
  /* background: linear-gradient(white 50%, #EA5136 50%);  */
}

.projectCartBtn {
  opacity: 1;
  transition: 1s;
}
.projectCartImg {
  overflow: hidden;
  transition: 1;
}
.projectCartImg > img {
  scale: 1;
  transition: 1s;
}
.projectCart .projectCartBtn {
  opacity: 0;
  transition: 1s;
}
.projectCart:hover .projectCartImg > img {
  scale: 1.1;
  transition: 1s;
}

.projectCart:hover .projectCartBtn {
  opacity: 1;
  transition: 1s;
}
.awardTitle {
  font-size: 180px;
  line-height: 180px;
  font-weight: bolder;
  color: rgba(255, 255, 255, 0.5);
}
/* .projectsCount{
     background: linear-gradient( #EA5136 50% ,rgba(255,255,255,0.1) 50%);  
  } */

.newsmarquee-container {
  width: 100%;
  height: 450px;
  overflow: hidden;
  padding: 10px;
}

.newsmarquee {
  display: flex;
  flex-direction: column;
  animation: newsmarqueeAnimation 20s linear infinite;
}

.newsmarquee:hover {
  animation-play-state: paused;
}

@keyframes newsmarqueeAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
/* .news{
    color: gray !important;
  }
  .news:hover{
    color: black !important;
    cursor: pointer;
  } */

/* Additional styling for the text */
.newsmarquee p {
  margin: 0;
  color: #30312f;
  font-family: sans-serif;
  font-size: 17px;
  line-height: 1.4em;
}

.gallery {
  width: 100vw;
  height: hidden;
  margin: auto;
  padding: 0;
  overflow-x: hidden;
}
.galImg {
  padding: 0;
  overflow: hidden;
}
.galImg img {
  transform: scale(1);
  transition: 1s;
  filter: grayscale(100%);
  width: 100%;
}

.galImg img:hover {
  transform: scale(1.1);
  transition: 1s;
  filter: grayscale(0%);
  cursor: pointer;
}
.con {
  text-decoration: none;
  color: #b0b0b0;
}
.con:hover {
  color: #727cb6;
}

.youtubImge {
  width: 100%;
  height: 100%;
  background-image: url("../../images/ytl.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.homeTop {
  display: block;
}
.homeTopM {
  display: none;
}

@media (max-width: 1680px) {
  .homeTopTejasImg > img {
    height: 120vh;
  }
}

@media (max-width: 1110px) {
  .homeTopTejasImg {
    display: none;
  }
  .awardTitle {
    font-size: 150px;
    line-height: 160px;
    font-weight: bolder;
  }
  .homeTopA {
    height: 100vh;
    background-image: url("../../images/newImage.png");
    object-fit: cover;
    background-size: 320px;
    background-position: left bottom;
    background-repeat: no-repeat;
    display: flex;
    align-items: end;
    justify-content: end;
    position: relative;
    overflow-y: hidden;
  }

  .mbDiv {
    height: 100px;
  }
}

@media (max-width: 990px) {
  .awardTitle {
    font-size: 100px;
    line-height: 110px;

    font-weight: bolder;
  }
  .gallery {
    width: 100vw;
    height: auto;
    margin: auto;
    padding: 0;
    overflow-x: hidden;
  }

  .youtubImge {
    width: 100%;
    height: 100%;

    background-image: url("../../images/ytm.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

@media (max-width: 770px) {
  .homeTop {
    display: none;
  }
  .homeTopM {
    display: block;
  }
  .awardTitle {
    font-size: 80px;
    line-height: 100px;

    font-weight: bolder;
  }
  .homeTopA > div {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media (max-width: 550px) {
 
  .awardTitle {
    font-size: 55px;
    line-height: normal;
    font-weight: bolder;
  }
  .youtubImge {
    width: 100%;
    height: 100%;

    background-image: url("../../images/yts.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
